import Axios, { AxiosRequestConfig, Canceler } from "axios";
import getConfig from "next/config";
import { mergeRight, omit, prop } from "ramda";

import { TEndpoint } from "#types/api.core";
import { TGlobalFilterSettings, TRequestSearch, TSearchResponse, TSettingsJobSiteSearch } from "#types/filter.core";
import { Country, TJobRecord, TOffersSuggested, TSettingsJobSite, TSimilarVacancies } from "#types/jobs";
import { isClientSide } from "@constants/index";
import ApiSeo from "@core/apiSeo.core";

const { jobsApiHost } = getConfig().publicRuntimeConfig;

export class JobsRepository extends ApiSeo {
  protected searchToken?: Canceler;
  protected readonly filterSettings: Map<string, TGlobalFilterSettings>;
  protected readonly endpoints: TEndpoint = {
    jobs: {
      search: "/v1/public/jobs/search",
      detail: "/v1/public/jobs/{jobSlug}",
      marks: "/v1/public/jobs/setting/{id}",
      suggested: "/v1/public/jobs/vacants/suggested/{jobSlug}",
      similar: "/v2/jobs/suggested/mimir-wisdom"
    },
    // TODO: Remove this endpoint in other repository
    filters: {
      countries: "/v1/public/countries/{companySlug}"
    },
    matcher: {
      declineInvitation: "/v1/matcher/decline"
    }
  };

  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: jobsApiHost }, baseOptions));
    this.filterSettings = new Map();
  }

  search(nextParams: TRequestSearch): Promise<TSearchResponse<TJobRecord>> {
    if (this.searchToken && isClientSide) this.searchToken("ABORTING_PREVIOUS_REQUEST");
    const withoutSearch = omit(["q"], nextParams);
    const querySearch = prop("q", nextParams);
    const params = {
      ...withoutSearch,
      queries: querySearch ? [{ field: "all", term: querySearch }] : []
    };
    return this.get<TSearchResponse<TJobRecord>>(this.endpoints.jobs.search, {
      params,
      cancelToken: new Axios.CancelToken((canceler) => {
        this.searchToken = canceler;
      })
    }).then((response) => response.data);
  }

  async getSelectedJob(jobSlug: string, companyShowSlug?: string): Promise<TJobRecord> {
    return this.get<TJobRecord>(this.endpoints.jobs.detail, { params: { jobSlug, companyShowSlug } }).then(
      (response) => response.data
    );
  }

  async getOffersSuggested(jobSlug: string): Promise<TOffersSuggested[]> {
    return this.get<TOffersSuggested[]>(this.endpoints.jobs.suggested, {
      params: { jobSlug }
    }).then((response) => response.data);
  }

  async getCompanyCountries(companySlug: string): Promise<Country[]> {
    return this.get<Country[]>(this.endpoints.filters.countries, {
      params: { companySlug }
    }).then((response) => response.data);
  }

  async getSettingsJobSite(params: TSettingsJobSiteSearch): Promise<TSettingsJobSite> {
    return this.get<TSettingsJobSite>(this.endpoints.jobs.marks, {
      params
    }).then((response) => response.data);
  }

  async getSimilarVacancies(vacantId: number | null, companyId?: number | null): Promise<TSimilarVacancies[]> {
    return this.get<TSimilarVacancies[]>(this.endpoints.jobs.similar, {
      params: {
        vacantId,
        companyId: companyId ?? companyId
      }
    }).then((response) => response.data);
  }
}

export default new JobsRepository();
