import { TFunction } from "next-i18next";
import { useMemo } from "react";
import { useRouter } from "next/router";
import { TRequestSearch } from "#types/filter.core";
import { fullBasePath, modulesWithSearch } from "@constants";
import { getAllUtms } from "@utils/url";

type TProps = {
  t: TFunction;
  appliedFilters: TRequestSearch;
  setFilters: (request: TRequestSearch) => Promise<void>;
};
export const useSearch = ({ t, appliedFilters, setFilters }: TProps) => {
  const router = useRouter();
  const interpolate = useMemo(() => {
    return {
      companySlug: router.query.companySlug || "[companySlug]",
      categorySlug: router.query.categorySlug || "[categorySlug]",
      jobSlug: router.query.jobSlug || "[jobSlug]"
    };
  }, [router.query]);

  const matrixRoutes = useMemo(() => {
    return modulesWithSearch.map((item) => ({
      ...item,
      route: t(item.route, interpolate),
      target: t(item.target, interpolate)
    }));
  }, [interpolate, t]);

  return (value: string) => {
    const [currentPath] = router.asPath.split("?");
    const notFoundPath = router.pathname;
    const rule = matrixRoutes.find((rule) => {
      if (notFoundPath === t("routes:notFound")) return rule.route === notFoundPath;
      return rule.route === currentPath;
    });
    const payloadSearch: TRequestSearch = {
      ...appliedFilters,
      q: value,
      paginator: { ...appliedFilters.paginator, page: 1 }
    };
    if (!rule || !rule.redirect) return setFilters(payloadSearch);
    const targetUrl = new URL(`${fullBasePath}${rule.target}`);
    const utms = getAllUtms();
    if (value) targetUrl.searchParams.set("q", value);
    for (const utm in utms) {
      targetUrl.searchParams.set(utm, utms[utm]);
    }

    router.push(targetUrl);
  };
};
