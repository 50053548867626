import { AuthContext, initJobsContext, initMegaMenuContext } from "@context";
import { FiltersRepository, FiltersWithAuthRepository } from "@repositories";
import { useContext, useEffect, useMemo, useState } from "react";

const useImplementationMegaMenu = ({ ...props }) => {
  const authContext = useContext(AuthContext);
  const [megaMenuContext, setMegaMenuContext] = useState({ ...initMegaMenuContext, ...props });
  const [contextValue, setContextValue] = useState({ ...initJobsContext, ...props });

  const filtersRepository = useMemo<typeof FiltersRepository>(() => {
    if (authContext.isAuthenticated) return FiltersWithAuthRepository;
    return FiltersRepository;
  }, [authContext.isAuthenticated]);

  useEffect(() => {
    filtersRepository.getFilterSettings().then((filterSettings) => {
      setContextValue((current) => ({ ...current, filterSettings }));
    });
  }, [filtersRepository]);

  return {
    megaMenuContext,
    setMegaMenuContext,
    setContextValue,
    contextValue
  };
};

export default useImplementationMegaMenu;
