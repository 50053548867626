import { MegaMenuContext } from "@context";
import useImplementationMegaMenu from "@hooks/magnetoUi/megaMenu/useImplementationMegaMenu";
import useDeviceDetected from "@hooks/useDeviceDetected";
import { MagnetoUIProvider } from "magneto365.ui";
import ParameterOriginChecker from "../parameterOriginChecker";
import MagnetoLoginHeaderComponent from "./magnetoLoginHeader.component";

const MagnetoUIHeader = ({ ...props }) => {
  const { megaMenuContext, setMegaMenuContext } = useImplementationMegaMenu(props);
  const device = useDeviceDetected();

  return (
    <ParameterOriginChecker parameter="app">
      <MegaMenuContext.Provider value={{ ...megaMenuContext, setMegaMenuContext }}>
        <MagnetoUIProvider device={device}>
          <MagnetoLoginHeaderComponent />
        </MagnetoUIProvider>
      </MegaMenuContext.Provider>
    </ParameterOriginChecker>
  );
};

export default MagnetoUIHeader;
