import { TMegaMenuCard, TMegaMenuContext } from "#types/megaMenu";
import { ELandingType } from "@enums/searchHit.enum ";
import { get } from "lodash";
import { createContext, useContext } from "react";

export const initMegaMenuContext: TMegaMenuContext = {
  [ELandingType.BY_LOCATION]: [],
  [ELandingType.BY_SECTOR]: [],
  [ELandingType.BY_OCCUPATION]: [],
  [ELandingType.BY_COMPANY]: [],
  [ELandingType.BY_OCCUPATION_CATEGORY]: [],
  setMegaMenuContext: () => (value: TMegaMenuContext) => value
};

export const MegaMenuContext = createContext<TMegaMenuContext>(initMegaMenuContext);

export const useMegaMenuContext = (key: string): TMegaMenuCard[] => {
  const context = useContext(MegaMenuContext);
  return get(context, key);
};

MegaMenuContext.displayName = "MegaMenuContext";
